export const Color = {
  base: '',
  black: '#000000',
  danger: '#d5233f',
  darkGray: '#656565',
  gradient_c1: '#438AD1',
  gradient_c2: '#0052A5',
  gradient_c3: '#002953',
  gray: '#767676',
  gray_1: '#D8D8D8',
  gray_2: '#95989A',
  gray_4: '#DCDCDC',
  gray_5: '#757575',
  gray_6: '#F2F2F2',
  gray_7: '#EEEEEE',
  gray_8: '#E8E8E8',
  gray_9: '#707070',
  gray_10: '#E4E4E4',
  green: '#598527',
  green_1: '#00A553',
  info: '#eba828',
  lightBlack: '#1b1b1b',
  lightGray: '#b7b7b7',
  lighterGray: '#F1F1F1',
  orange: '#CC4700',
  orange_1: '#F0521F',
  primary: '#0052a5',
  primaryLight: '#006cd7',
  primaryLight_2: '#428AD3',
  primaryLighter: '#E5EDF6',
  red: '#D5233F',
  secondary: '#006cd7',
  secondaryDark: '#a7162d',
  skyblue: '#9BC7F2',
  veryLightGrey: '#cbcbcb',
  watermark: '#605E5E',
  white: '#ffffff'
};
