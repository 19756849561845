import React from 'react';
import { LoaderProvider } from '../../context/LoaderContext';
import { ModalProvider } from '../../context/ModalContext';
import App from './App';
import { Debug } from '../../views/Debug';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  componentDidCatch(error, errorInfo) {
    // Catch errors in any components below and re-render with error message
    this.setState({
      error: error,
      errorInfo: errorInfo
    });
    // You can also log error messages to an error reporting service here
  }

  render() {
    if (this.state.errorInfo) {
      // Error path
      return (
        <div>
          <h2>Something went wrong.</h2>
            {this.state.error && this.state.error.toString()}
            <br />
            {this.state.errorInfo.componentStack}
          <Debug />
        </div>
      );
    }
    // Normally, just render children
    return this.props.children;
  }
}

const AppContainer = () => {
  return (
    <ErrorBoundary>
      <LoaderProvider>
        <ModalProvider>
          <App />
        </ModalProvider>
      </LoaderProvider>
    </ErrorBoundary>
  );
};

export default AppContainer;
