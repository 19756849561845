export const COLLAPSES = [
  {
    title: 'recommendations_text_8',
    content: 'recommendations_text_9'
  },
  {
    title: 'recommendations_text_10',
    content: 'recommendations_text_11'
  },
  {
    title: 'recommendations_text_12',
    content: 'recommendations_text_13'
  },
  {
    title: 'recommendations_text_14',
    content: 'recommendations_text_15'
  },
  {
    title: 'recommendations_text_16',
    content: 'recommendations_text_17'
  }
];
