export const ONBOARDING_FINISHED = 'ONBOARDING_FINISHED';
export const EXPOSURE_ONBOARDING_FINISHED = 'EXPOSURE_ONBOARDING_FINISHED';
export const START_SCREEN_SHOWED = 'START_SCREEN_SHOWED';
export const UPLOAD_HISTORICAL_DATA_REQUESTED = 'UPLOAD_HISTORICAL_DATA_REQUESTED';
export const UPLOAD_HISTORICAL_DATA_FINISHED = 'UPLOAD_HISTORICAL_DATA_FINISHED';
export const UPLOAD_HISTORICAL_DATA_ENDED = 'UPLOAD_HISTORICAL_DATA_ENDED';
export const FIRST_DIAGNOSIS_FINISHED = 'FIRST_DIAGNOSIS_FINISHED';
export const MIGRATION_FINISHED = 'MIGRATION_FINISHED';
export const APP_STATE_CLEARED = 'APP_STATE_CLEARED';
export const DATA_FROM_NEWEST_VERSION_MARKED = 'DATA_FROM_NEWEST_VERSION_MARKED';
export const LANGUAGE_CHANGED = 'LANGUAGE_CHANGED';
export const ALL_DATA_CLEARED = 'ALL_DATA_CLEARED';
export const UPLOAD_HISTORICAL_DATA_ERROR_MESSAGE_HIDDEN = 'UPLOAD_HISTORICAL_DATA_ERROR_MESSAGE_HIDDEN';
export const REGISTRATION_FINISHED = 'REGISTRATION_FINISHED';
export const FONT_SCALE_FETCHED = 'FONT_SCALE_FETCHED';
export const WARNING_IN_EUROPE_TERM_TOGGLE = 'WARNING_IN_EUROPE_TERM_TOGGLE';
export const FIRST_RUN = 'FIRST_RUN';
export const APPLICATION_RATED = 'APPLICATION_RATED';
export const SHOWING_RATE_APPLICATION_SET = 'SHOWING_RATE_APPLICATION_SET';
export const RATE_APPLICATION_SHOWED = 'RATE_APPLICATION_SHOWED';
