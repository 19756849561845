import styled from 'styled-components';

export const Test = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: auto;
  border: 3px solid #73ad21;
  z-index: 1000;

  textarea {
    width: 100%;
  }
`;

export const Button = styled.div`
  position: fixed;
  bottom: 0;
  right: 0;
  width: 20px;
  height: 20px;
  border: 3px solid #73ad21;
  z-index: 1000;

  textarea {
    width: 100%;
    height: auto;
  }
`;
