export const Routes = {
  AddTranslation: 'addTranslation',
  CurrentRestrictions: 'currentRestrictions',
  Daily: 'daily',
  DailyData: 'dailyData',
  Diagnosis: 'diagnosis',
  Home: 'home',
  HowItWorks: 'howItWorks',
  IAmSick: 'iAmSick',
  ImportantInformation: 'importantInformation',
  InfoVaccinations: 'InfoVaccinations',
  LabTest: 'labTest',
  LabTestResult: 'labTestResult',
  Menu: 'menu',
  HistoryActivities: 'historyActivities',
  PinReceiver: 'pinReceiver',
  PrivacyPolicy: 'privacyPolicy',
  PrivacyPolicyDetails: 'privacyPolicyDetails',
  Recommendations: 'recommendations',
  Regulations: 'regulations',
  ReportBug: 'reportBug',
  RiskTest: 'riskTest',
  RiskTestData: 'riskTestData',
  Settings: 'settings',
  SettingsBluetooth: 'settingsBluetooth',
  SettingsLanguages: 'settingsLanguages',
  SettingsNotifications: 'settingsNotifications',
  Statistics: 'statistics',
  SummaryRiskTest: 'summaryRiskTest',
  UploadHistoricalData: 'uploadHistoricalData',
  UserData: 'userData',
  UserDataChange: 'userDataChange',
  UserDataSettings: 'userDataSettings',
  WarningInEurope: 'warningInEurope'
};
