export const COLLAPSES = [
  {
    title: 'recommendations_text_27',
    content: 'recommendations_text_28'
  },
  {
    title: 'recommendations_text_29',
    content: 'recommendations_text_30'
  },
  {
    title: 'recommendations_text_31',
    content: 'recommendations_text_32'
  },
  {
    title: 'recommendations_text_33',
    content: 'recommendations_text_34'
  }
];
