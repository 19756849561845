import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import * as Styled from './Debug.styled';

const Debug = () => {
  const [showDebug, setShowDebug] = useState(false);
  const { actions } = useSelector(state => state.debug);

  const renderActions = () => {
    return actions.map(value => {
      return `${JSON.stringify(value)}\n`;
    });
  };
  return (
    <>
      {showDebug && (
        <Styled.Test>
          <textarea rows={100} defaultValue={renderActions()} />
        </Styled.Test>
      )}
      <Styled.Button onClick={() => setShowDebug(prev => !prev)}>5</Styled.Button>
    </>
  );
};

export default Debug;
