export const DATA_TYPE = {
  FILLED_DIAGNOSIS: 1,
  NATIVE_STATE: 11,
  CLEAR_ALL_DATA: 37,
  HISTORICAL_DATA: 43,
  NATIVE_SERVICES_STATUS: 51,
  NATIVE_SERVICES_STATE: 52,
  EXPOSURE_STATISTICS: 61,
  NATIVE_VERSION: 62,
  LANGUAGE: 63,
  TURN_OFF: 64,
  REVOKE_EN: 66,
  FONT_SCALE: 65,
  BACK_PRESSED: 67,
  RATING_APP: 68,
  CHANGE_SCREEN: 69,
  DISTRICTS_STATUS: 70,
  FORCE_DISTRICTS_STATUS: 71,
  DISTRICT_ACTION: 72,
  SUBSCRIBED_DISTRICTS: 73,
  UPLOAD_LAB_TEST_PIN: 80,
  LAB_TEST_SUBSCRIPTION: 81,
  LAB_TEST_PIN: 82,
  LIST_ACTIVITIES: 90,
  CONFIRM_ACTIVITIES: 91,
  GET_NOTIFICATION_STATUS: 100,
  SET_NOTIFICATION_STATUS: 101,
  SUMMARY_STATISTICS: 102,
  EXPOSURE_AGGREGATE_STATISTICS: 103,
  DETAILS_STATISTICS: 104,
  SMS_SENDER: 105
};
